import { Injectable } from '@angular/core';
import { UserInfo } from '../../constants/common.enum';
import { Constants } from '../../constants/constants';
import { UtilsService } from '../utils/utils.service';
import { Store } from '@ngrx/store';
import * as AppState from '../../../state/app.state';
import * as AppActions from '../../../state/app.actions';
import { SocketService } from '../socket/socket.service';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { HttpWrapperService } from '../http-service/http-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  regex: RegExp;

  constructor(
    private utils: UtilsService,
    private socketService: SocketService,
    private store: Store<AppState.State>,
    private httpClientWrapper: HttpWrapperService
  ) {
    this.regex = new RegExp(/^([a-z]+\:\/{2})?([\w-]+\.[\w-]+\.\w+)$/);
  }

  init() {
    return new Promise<void>((resolve, reject) => {
      const userInfo = JSON.parse(
        localStorage.getItem(Constants.userInfo)
      ) as UserInfo;
      if (userInfo && userInfo.user_id && userInfo.user_token) {
        this.store.dispatch(
          new AppActions.LoadUserDetailsAction(userInfo.user_id)
        );
        this.store.dispatch(new AppActions.UpdateUserInfoAction(userInfo));
      }
      resolve();
    });
  }

  // TODO: Need to have a different way to get client configuration.
  findOutClient() {
    let url = location.host;
    if (!!url.match(this.regex) && url.split('.')[0] !== 'www') {
      url = url.split('.')[0];
      this.utils.setIsTenantCode(false);
      this.setClientWithDomain(url);
    } else {
      this.utils.setIsTenantCode(true);
      this.utils.setClient({});
      this.utils.setAppInfo(Constants.clients.optimy);
    }
  }

  setClientWithDomain(url) {
    const client = this.utils.getClient();
    this.utils.setAppInfo(Constants.clients.optimy);
    if (
      (this.convertToLowerAndRemoveSpace(client?.tenantName) === url ||
        this.convertToLowerAndRemoveSpace(client?.tenantCode) === url) &&
      client?.tenant_id
    ) {
      this.utils.setClient({
        ...client,
        tenantCode: url
      });
      return;
    }
    this.utils.setClient({ tenantCode: url, tenantName: url });
  }

  convertToLowerAndRemoveSpace(value: string) {
    return value?.toLowerCase().replace(/\s/g, '');
  }

  getBuildNumber(): Observable<any> {
    let baseURL = environment.getBuildNumberApi;
    return this.httpClientWrapper
      .postReqForLambda(
        baseURL,
        {
          env: environment.environmentName,
          getBuildNumber: true
        },
        {}
      )
      .pipe(
        map((res: any) => {
          return res?.data;
        })
      )
      .pipe(
        catchError(() => {
          return of(null);
        })
      );
  }
}
