import { UserDetails } from 'src/app/core/constants/dashboard-modal';
import { getUserDetailsSelector } from '../../state/app.selectors';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { Notification } from 'src/app/core/constants/common.enum';
import { UtilsService } from 'src/app/core/services/utils/utils.service';
import * as AppState from '../../state/app.state';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { optimyRoutes } from '../../core/constants/optimyRoutes';
import { Constants } from '../../core/constants/constants';
import { RoleAccessPipe } from '../../core/pipes/roleAccess.pipe';
import { Roles } from '../../core/constants/schedule-config';
import * as moment from 'moment';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  notificationList: Notification[];
  roles = Roles;

  @ViewChild('closeLink') closeLink: NgbPopover;

  userDetails: UserDetails;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private utils: UtilsService,
    private router: Router,
    private push: SwPush,
    private appStore: Store<AppState.State>,
    private modalService: NgbModal,
    private roleAccessPipe: RoleAccessPipe,
    private route: ActivatedRoute
  ) {
    this.push.messages.subscribe((msg) => {
      console.log('push message', msg);
      this.getNotificationList();
    });
  }

  ngOnInit(): void {
    this.getStoreInformation();
    this.utils.updateNotificationList$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: boolean) => {
        if (data) {
          this.getStoreInformation();
        }
      });
  }

  private getStoreInformation(): void {
    this.appStore
      .pipe(select(getUserDetailsSelector), takeUntil(this.destroy$))
      .subscribe((response: UserDetails) => {
        this.userDetails = response;
        if (this.userDetails) {
          this.getNotificationList();
        }
      });
  }

  getNotificationList() {
    this.utils.getNotification(this.userDetails?.user_id).subscribe(
      (response: Notification[]) => {
        if (response?.length) {
          let newNotificationWithTime = [];
          response.forEach((list: Notification) => {
            if (
              !this.findDuplicateNotification(newNotificationWithTime, list)
            ) {
              if (list?.attr?.notification?.body) {
                let guestObj = { ...list };
                if (list?.created_dt) {
                  const timeInfo = this.getTimeForNotification(list.created_dt);
                  guestObj.time = timeInfo;
                }
                newNotificationWithTime.push(guestObj);
              }
            }
          });
          this.notificationList = newNotificationWithTime.sort((a, b) => {
            const date1: any = new Date(a.created_dt);
            const date2: any = new Date(b.created_dt);
            return date2 - date1;
          });
        }
      },
      (error) => {
        console.log('error in notification', error);
      }
    );
  }

  findDuplicateNotification(newNotificationWithTime, list) {
    const notification = newNotificationWithTime?.find(
      (i) =>
        +i.attr?.data?.queue_position_id === +list.attr?.data?.queue_position_id
    );
    return notification;
  }

  getTimeForNotification(dateTime: string) {
    if (dateTime) {
      const notificationTime = moment.utc(new Date(dateTime)).fromNow();
      return notificationTime === 'in a few seconds'
        ? 'a few seconds ago'
        : notificationTime;
    }
  }

  dismissNotification(id) {
    this.utils.dismissNotification(`${id}`).subscribe(
      (response) => {
        this.notificationList = this.notificationList.filter(
          (obj) => obj.id !== id
        );
        if (!this.notificationList.length) {
          this.closeLink.close();
        }
        this.getNotificationList();
      },
      (error) => {
        console.log('error in notification', error);
      }
    );
  }

  settings() {
    const url = optimyRoutes.settingsOutlet.notificationConfig;
    this.router.navigate([
      Constants.routes.dashboard,
      Constants.routes.settings,
      url
    ]);
    this.closePopOver();
  }

  closePopOver() {
    this.closeLink.close();
  }

  onBodyClick(id: number) {
    this.router
      .navigate(['queue/board'], {
        relativeTo: this.route,
        queryParams: { queue_position_id: id, action: 'view' }
      })
      .then(() => {
        this.utils.onClickOfInAppNotification(true);
      });
    this.closePopOver();
  }
}
