import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './app.state';

// Get the whole state for the save modules (save slice)
const getAppFeatureState = createFeatureSelector<State>('app');

export const getUserInfoSelector = createSelector(
  getAppFeatureState,
  (state) => state.userInfo
);
export const getUserDetailsSelector = createSelector(
  getAppFeatureState,
  (state) => state.userDetails
);
export const getQueueCallsAndListsSelector = createSelector(
  getAppFeatureState,
  (state) => state.queueCallsAndLists
);
export const getQueueListSelector = createSelector(
  getAppFeatureState,
  (state) =>
    state.userDetails?.queue_list ? state.userDetails?.queue_list : []
);
export const getPendingCallListSelector = createSelector(
  getAppFeatureState,
  (state) => state.pendingCalls
);
export const getInProgressCallListSelector = createSelector(
  getAppFeatureState,
  (state) => state.inProgressCalls
);
export const getScheduledCallListSelector = createSelector(
  getAppFeatureState,
  (state) => state.scheduledCalls
);
export const getCallInfoSelector = (callQueueId: number, type) =>
  createSelector(getAppFeatureState, (state) => {
    const queueCalls = state[type];
    return queueCalls?.find((res) => res?.queue_position_id === callQueueId);
  });
export const getActiveCallQueueId = createSelector(
  getAppFeatureState,
  (state) => state.activeCallQueueId
);
export const getAgentListSelector = createSelector(
  getAppFeatureState,
  (state) => state.agentList
);
// get only the state for the agentStatusUpdate
export const getAgentStatusUpdateSelector = createSelector(
  getAppFeatureState,
  (state) => state.agentStatusUpdate
);

export const getReportInfoSelector = createSelector(
  getAppFeatureState,
  (state) => state.reportInfo
);

export const getDashboardFilterInfoSelector = createSelector(
  getAppFeatureState,
  (state) => state.dashboardFilterInfo
);

export const getLanguageList = createSelector(
  getAppFeatureState,
  (state) => state.languageList
);

export const getOutboundInfo = createSelector(
  getAppFeatureState,
  (state) => state?.outBoundInfo
);
